<template>
  <main class="error-page">
    <div class="robotpage-top-half">
      <div id="robot_holder" direction="ltr" style="direction: ltr">
        <!--?xml version="1.0" encoding="utf-8"?-->
        <svg
          id="brokebotSVG"
          version="1.1"
          xmlns="http://www.w3.org/2000/svg"
          xmlns:xlink="http://www.w3.org/1999/xlink"
          x="0px"
          y="0px"
          viewBox="0 0 551 239"
          enable-background="new 0 0 551 239"
          xml:space="preserve"
          preserveAspectRatio="xMidYMax"
        >
          <text
            id="errorCodeTxt"
            transform="matrix(1 0 0 1 6.7231 240.0011)"
            class="error_code"
          >
            {{ status }}
          </text>
          <g id="robotHead">
            <rect
              x="359.2"
              y="173.1"
              class="robot_limbs_and_ears"
              width="6.4"
              height="23.3"
            ></rect>
            <rect
              x="348.1"
              y="185.4"
              class="robot_body"
              width="28.5"
              height="11"
            ></rect>
            <path
              class="robot_limbs_and_ears"
              d="M392,197.7h-59.2l0,0c0-3.7,3-6.8,6.8-6.8h45.6C388.9,190.9,392,193.9,392,197.7L392,197.7z"
            ></path>
            <rect
              x="288.5"
              y="207.5"
              class="robot_limbs_and_ears"
              width="147.7"
              height="18.7"
            ></rect>
            <path
              class="robot_body"
              d="M419.3,238.9H305.5c-5.5,0-10-4.5-10-10V207c0-5.5,4.5-10,10-10h113.8c5.5,0,10,4.5,10,10v21.9
							C429.3,234.4,424.8,238.9,419.3,238.9z"
            ></path>
            <circle
              class="robot_eye_whites"
              cx="317"
              cy="216.4"
              r="11.7"
            ></circle>
            <circle
              class="robot_eye_whites"
              cx="407.7"
              cy="216.4"
              r="11.7"
            ></circle>
            <g id="eyesMove">
              <g id="eyesBlink">
                <rect
                  x="311"
                  y="210.4"
                  class="robot_eyes"
                  width="12.1"
                  height="12.1"
                ></rect>
                <polygon
                  class="robot_eyes_highlight"
                  points="323.1,210.4 311,210.4 323.1,222.5 			"
                ></polygon>
                <rect
                  x="401.7"
                  y="210.4"
                  class="robot_eyes"
                  width="12.1"
                  height="12.1"
                ></rect>
                <polygon
                  class="robot_eyes_highlight"
                  points="413.7,210.4 401.7,210.4 413.7,222.5 			"
                ></polygon>
              </g>
            </g>
            <rect
              x="339"
              y="219.9"
              class="robot_limbs_and_ears"
              width="5.3"
              height="9.8"
            ></rect>
            <rect
              x="347.3"
              y="219.9"
              class="robot_limbs_and_ears"
              width="5.3"
              height="9.8"
            ></rect>
            <rect
              x="355.6"
              y="219.9"
              class="robot_limbs_and_ears"
              width="5.3"
              height="9.8"
            ></rect>
            <rect
              x="363.9"
              y="219.9"
              class="robot_limbs_and_ears"
              width="5.3"
              height="9.8"
            ></rect>
            <rect
              x="372.2"
              y="219.9"
              class="robot_limbs_and_ears"
              width="5.3"
              height="9.8"
            ></rect>
            <rect
              x="380.5"
              y="219.9"
              class="robot_limbs_and_ears"
              width="5.3"
              height="9.8"
            ></rect>
            <circle class="robot_antenna" cx="362.4" cy="167.9" r="10"></circle>
            <circle
              class="robot_antenna_highlight"
              cx="363.4"
              cy="165.9"
              r="5.5"
            ></circle>
          </g>
          <g id="robotbody">
            <rect
              x="154.3"
              y="184.1"
              class="robot_limbs_and_ears"
              width="7"
              height="16.7"
            ></rect>
            <path
              class="robot_limbs_and_ears"
              d="M162.6,234.1h-9.7c-2.8,0-5-2.2-5-5v-22.7c0-2.8,2.2-5,5-5h9.7c2.8,0,5,2.2,5,5v22.7
							C167.6,231.8,165.3,234.1,162.6,234.1z"
            ></path>
            <path
              class="robot_hands_feet"
              d="M172.7,238.9c0-8.2-6.7-14.9-14.9-14.9c-8.2,0-14.9,6.7-14.9,14.9H172.7z"
            ></path>
            <circle
              class="robot_joints_and_belly"
              cx="157.8"
              cy="203.1"
              r="5.1"
            ></circle>
            <path
              class="robot_limbs_and_ears"
              d="M221.3,234.1h-9.7c-2.8,0-5-2.2-5-5v-22.7c0-2.8,2.2-5,5-5h9.7c2.8,0,5,2.2,5,5v22.7
							C226.3,231.8,224,234.1,221.3,234.1z"
            ></path>
            <path
              class="robot_hands_feet"
              d="M231.3,238.9c0-8.2-6.7-14.9-14.9-14.9c-8.2,0-14.9,6.7-14.9,14.9H231.3z"
            ></path>
            <circle
              id="leftKnee"
              class="robot_joints_and_belly"
              cx="216.4"
              cy="203.1"
              r="5.1"
            ></circle>

            <g id="upperBody">
              <rect
                x="146"
                y="143"
                transform="matrix(-0.968 -0.2511 0.2511 -0.968 362.8447 338.4026)"
                class="robot_limbs_and_ears"
                width="114"
                height="6.1"
              ></rect>
              <g id="leftArm">
                <path
                  id="leftOuterClaw"
                  class="robot_hands_feet"
                  d="M247.7,212.6l21.1,21.1c5.8-5.8,5.8-15.3,0-21.1C262.9,206.7,253.5,206.7,247.7,212.6
									z"
                ></path>
                <path
                  id="leftInnerClaw"
                  class="robot_hands_feet"
                  d="M247.7,212.6c-5.8,5.8-5.8,15.3,0,21.1l21.1-21.1
									C262.9,206.7,253.5,206.7,247.7,212.6z"
                ></path>
                <rect
                  x="254.7"
                  y="161.1"
                  class="robot_limbs_and_ears"
                  width="7"
                  height="25"
                ></rect>
                <path
                  class="robot_limbs_and_ears"
                  d="M263,217.5l-9.7,0c-2.8,0-5-2.3-5-5l0-22.7c0-2.8,2.3-5,5-5l9.7,0c2.7,0,5,2.3,5,5l0,22.7
									C268,215.3,265.7,217.5,263,217.5z"
                ></path>
                <circle
                  class="robot_joints_and_belly"
                  cx="258.2"
                  cy="186.5"
                  r="5.1"
                ></circle>
              </g>
              <path
                class="robot_body"
                d="M270.4,163.5c1.7-6.7-2.3-13.6-9-15.4c-6.7-1.7-13.6,2.3-15.4,9c-1.7,6.7,2.3,13.6,9,15.4
								C261.8,174.3,268.7,170.3,270.4,163.5z"
              ></path>

              <rect
                x="130"
                y="138.3"
                transform="matrix(0.8845 0.4666 -0.4666 0.8845 83.8309 -45.3494)"
                class="robot_limbs_and_ears"
                width="7"
                height="16.7"
              ></rect>
              <g id="rightLowerArm">
                <path
                  id="rightInnerClaw"
                  class="robot_hands_feet"
                  d="M119,181.3l21.1,21.1c5.8-5.8,5.8-15.3,0-21.1C134.2,175.5,124.8,175.5,119,181.3z"
                ></path>
                <path
                  id="rightOuterClaw"
                  class="robot_hands_feet"
                  d="M119,181.3c-5.8,5.8-5.8,15.3,0,21.1l21.1-21.1C134.2,175.5,124.8,175.5,119,181.3z"
                ></path>
                <path
                  class="robot_limbs_and_ears"
                  d="M134.5,186.2l-9.7,0.1c-2.7,0-5-2.2-5-5l-0.2-22.7c0-2.7,2.2-5,5-5l9.7-0.1c2.7,0,5,2.2,5,5l0.2,22.7
									C139.5,183.9,137.3,186.2,134.5,186.2z"
                ></path>
              </g>
              <circle
                class="robot_joints_and_belly"
                cx="129.4"
                cy="154.3"
                r="5.1"
              ></circle>
              <path
                class="robot_body"
                d="M154.3,133.4c1.7-6.7-2.3-13.6-9-15.4c-6.7-1.7-13.6,2.3-15.4,9c-1.7,6.7,2.3,13.6,9,15.4
								C145.7,144.2,152.6,140.2,154.3,133.4z"
              ></path>
              <path
                class="robot_hands_feet"
                d="M210.2,119.6l-1.4-1.5c0.1-0.1,9.1-8.7,7.2-15.5l1.9-0.5C220.2,110,210.6,119.2,210.2,119.6z"
              ></path>
              <path
                class="robot_hands_feet"
                d="M215.9,117.4l-1.3-1.5c0.4-0.3,9.3-7.5,17.2-7.7l0,2C224.6,110.3,216,117.3,215.9,117.4z"
              ></path>
              <path
                class="robot_body"
                d="M210,116l-0.7-1.9c0.1,0,11.8-4.5,12.6-11.5l2,0.3C222.9,111.1,210.5,115.8,210,116z"
              ></path>

              <rect
                x="203.8"
                y="108.1"
                transform="matrix(0.7809 0.6246 -0.6246 0.7809 121.0947 -105.8214)"
                class="robot_limbs_and_ears"
                width="15.2"
                height="23.2"
              ></rect>
              <path
                class="robot_body"
                d="M217.3,207.9l-62.6-16.2c-5.3-1.4-8.5-6.9-7.2-12.2l19.6-58.3c1.4-5.3,6.4-8.7,11.2-7.4l56.2,14.6
								c4.8,1.2,7.6,6.6,6.2,11.9l-11.2,60.5C228.1,206,222.6,209.3,217.3,207.9z"
              ></path>
              <path
                class="robot_joints_and_belly"
                d="M211.5,184.8l-40.4-10.5c-3.4-0.9-5.5-4.4-4.6-7.9l12.7-37.6c0.9-3.4,4.1-5.6,7.2-4.8l36.3,9.4
								c3.1,0.8,4.9,4.3,4,7.7l-7.2,39.1C218.5,183.6,214.9,185.6,211.5,184.8z"
              ></path>
            </g>
          </g>
          <defs></defs>
        </svg>
      </div>
    </div>

    <div class="robotpage-bottom-half">
      <div>
        <p
          id="robot-text"
          style="max-width: 800px; padding-top: 40px; margin: auto"
        >
          {{ title }}
        </p>
        <p>{{ description }}</p>
        <div class="robot-buttons">
          <router-link
            class="robot-buttons"
            :to="{ name: $ROUTER.NAME.HOME }"
            >{{ action }}</router-link
          >
        </div>
      </div>
      <div class="clear"></div>
    </div>
  </main>
</template>

<script>
export default {
  name: 'errorTemplate',
  data() {
    return {
      status: null,
      title: null,
      description: null,
      action: null,
    }
  },

  mounted() {
    switch (this.$route.name) {
      case this.$ROUTER.NAME.ERROR.FORBIDDEN:
        this.status = 403
        this.title = this.$t('web.pageForbiddenTitle')
        this.description = this.$t('web.pageForbiddenDescription')
        this.action = this.$t('web.pageForbiddenAction', {
          productName: process.env.VUE_APP__PRODUCT_NAME,
        })
        break
      case this.$ROUTER.NAME.ERROR.NOT_FOUND:
        this.status = 404
        this.title = this.$t('web.pageNotFoundTitle')
        this.description = this.$t('web.pageNotFoundDescription')
        this.action = this.$t('web.pageNotFoundAction', {
          productName: process.env.VUE_APP__PRODUCT_NAME,
        })
        break
    }
  },
}
</script>

<style scoped lang="scss">
.error-page {
  position: fixed;
  z-index: 10000;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #2f4050;
  margin: 0;
  padding: 0;
  text-align: center;
  color: #3fdabb;
}
p {
  margin: 0;
  margin-bottom: 1.5em;
}

.robotpage-top-half {
  background-color: #d5ebda;
  overflow: hidden;
}

#errorCodeTxt {
  color: #ceddd1;
}

#brokebotSVG {
  height: 259px;
  margin-left: auto;
  margin-right: auto;
  margin-top: 111px;
  font-family: 'Open Sans', sans-serif;
  font-weight: bold;
  width: 100%;
}

#robot-text {
  color: #e54560;
  text-align: center;
  font-size: 3em;
  margin-top: 0.5em;
  margin-bottom: 0.2em;
  padding: 0;
}

#robotpage-bottom-half {
  font-family: 'Open Sans', sans-serif;
}

.robot-buttons {
  margin-left: auto;
  margin-right: auto;
}

a.robot-buttons {
  font-size: 1em;
  background-color: #1ab394;
  padding: 0.5em 1.5em;
  margin: 0.5em 0.2em 0em 0.2em;
  text-align: center;
  border-radius: 0.3em;
  text-decoration: none;
  color: white;
}

a.robot-buttons:hover {
  cursor: pointer;
  background-color: #18a689;
}

.search-robot {
  width: 480px;
  margin-left: auto;
  margin-right: auto;
}

.search-robot-box {
  width: 305px;
  float: left;
  padding: 0.5em;
  border-radius: 0.3em;
  font-size: 1em;
  margin: 0;
  border: 0;
}

.search-robot-buttons {
  font-size: 1em;
  background-color: #55bf9b;
  padding: 0.5em;
  border-radius: 0.3em;
  text-decoration: none;
  color: #f2ecba;
  width: 150px;
  float: right;
  margin: 0;
  border: 0;
}

.clear {
  clear: both;
}

.search-robot-buttons:hover {
  cursor: pointer;
  background-color: #8bc99a;
  color: #f2ecba;
}

.robot-links {
  text-align: center;
  color: #f2ecba;
  margin-top: 1em;
}

.robot-links a {
  color: #8bca9b;
  text-decoration: none;
  padding: 0.5em;
}

.robot-links a:hover {
  color: #f2ecba;
  text-decoration: none;
}

.robot_limbs_and_ears {
  fill: #09334f;
}

.robot_joints_and_belly {
  fill: #5e94b6;
}

.error_code {
  fill: #accab2;
  font-size: 322px;
}

.robot_body {
  fill: #00658f;
}

.robot_eye_whites {
  fill: #f3ecba;
}

.robot_hands_feet {
  fill: #1ab394;
}

.robot_antenna {
  fill: #1ab394;
}

.robot_antenna_highlight {
  fill: #1bc8a8;
}

.robot_eyes {
  fill: #1ab394;
}

.robot_eyes_highlight {
  fill: #1ab394;
}
</style>
